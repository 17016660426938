import React, { useState } from "react";

const useHttp = () => {
  const [loading, setLoading] = useState(false);

  const sendRequest = async (requestInfo, handleData) => {
    setLoading(true);

    try {
      const response = await fetch("https://ddw-interieur-backend-9ef2e-default-rtdb.europe-west1.firebasedatabase.app/projects.json", {
        method: requestInfo.method,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.log("Something went wrong");
      }

      const data = await response.json();

      handleData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    sendRequest,
    loading,
  };
};

export default useHttp;
