import React from "react";

import "./ProjectThumbnail.css";

const ProjectThumbnail = (props) => {
  let selected = false;

  if (props.selectedId === props.id) {
    selected = true;
  }

  const changePicture = () => {
    props.changePicture(props.photo.image, props.id);
  };

  return (
    <div className="ddw__projectsgroup-thumbnail">
      {selected ? (
        <img
          className="ddw__projectsgroup-thumbnail-selected"
          src={`data:image/jpg;base64,${props.photo.image}`}
          onClick={changePicture}
        />
      ) : (
        <img
          className="ddw__projectsgroup-thumbnail-img"
          src={`data:image/jpg;base64,${props.photo.image}`}
          onClick={changePicture}
        />
      )}
    </div>
  );
};

export default ProjectThumbnail;
