import React from "react";

import Homepageicon from '../assets/Homepage-icon.png';
import "./Home.css";

import Lottie from 'lottie-react';
import animation from '../assets/animations/homepage-animation.json';


const Home = () => {
  return (
    <div className="ddw__home-container" id="home">
      <div className="ddw__home-welcome">
        <h1>DDW interieur</h1>
        <p>
          De vakman voor iedere klus.
        </p>
      </div>
      <div className="ddw__home-picture">
        <Lottie style={{height: 350}}loop={false} animationData={animation}/>
      </div>
    </div>
  );
};

export default Home;
