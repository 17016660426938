import React from "react";

import "./Footer.css";

import { ImLocation } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="ddw__footer-container" id="contact">
      <div className="ddw__footer-top">
        <h1>Contactgegevens</h1>
        <p>
          <ImLocation /> : Piccardtstraat 3 4461LA Goes
        </p>
        <p>
          <HiOutlineMail /> : daan@ddwinterieur.nl
        </p>
        <p>
          <BsTelephone /> : 0618979340
        </p>
        <p>KvK nummer : 69573158</p>
      </div>
    </div>
  );
};

export default Footer;
