import React from "react";

import "./Aboutme.css";
import profilepicture from '../assets/ddw&ko-test.jpg';

const Aboutme = () => {
  return (
    <div className="ddw__aboutme-container" id="aboutme">
      <div className="ddw__aboutme-description">
        <h1>Over mij</h1>
        <p>
          Al sinds ik mij kan herinneren vind ik het leuk om zelf dingen te
          bedenken en te maken van hout. Daarom heb ik gekozen voor het hout en
          meubileringscollege in Rotterdam. Hier heb ik de fijne kneepjes van
          het vak geleerd en heb ik kunnen groeien in mijn handigheid. Toch vond
          ik het erg leuk om in de toekomst een eigen bedrijf te hebben dus ik
          heb na mijn opleiding interieurbouwer ook nog een opleiding meewerkend
          leidinggevende gedaan. Daarbij ben ik werkzaam geweest bij een
          jachtinterieurbouwbedrijf. Hier heb ik twee jaar gewerkt en op die
          manier kon ik binnen de houtbranche blijven en ook groeien in de
          administratieve kant van een bedrijf hebben. Na die opleiding ben ik
          mijn eigen bedrijf begonnen en sindsdien ben ik daar druk mee bezig.
        </p>
      </div>
      <div className="ddw__aboutme-picture">
        <img src={profilepicture} alt="" />
      </div>
      <div className="ddw__aboutme-border"></div>
    </div>
  );
};

export default Aboutme;
