import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import ProjectOverview from "./components/routes/ProjectOverview";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Aboutme from "./components/Aboutme";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/overmij", element: <Aboutme /> },
  { path: "/projecten", element: <ProjectOverview /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={router}>
    <App />
  </RouterProvider>
);
