import React, { useState } from "react";
import "./Navbar.css";
import logo from "../assets/ddw-logo.png";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

import { Link as LinkDom, useLocation } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const currentLocation = useLocation();

  let atProjects = false;

  const onShowNavBarClickedHandler = () => {
    if (showNavbar) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  };

  if (currentLocation.pathname === "/projecten") {
    atProjects = true;
  }

  return (
    <div className="ddw__navbar-container">
      <div className="ddw__navbar-logo">
        <LinkDom to="/">
          <img src={logo} alt="logo" />
        </LinkDom>
      </div>
      {!atProjects && (
        <div className="ddw__navbar-links">
          <LinkScroll
            to="aboutme"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Over mij
          </LinkScroll>
          <LinkScroll
            to="projects"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Projecten
          </LinkScroll>
          <LinkScroll
            to="contact"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Contact
          </LinkScroll>
        </div>
      )}
      <button className="ddw__nav-btn" onClick={onShowNavBarClickedHandler}>
        {!showNavbar && <FaBars />}
      </button>
      {showNavbar && (
        <div className="ddw__navbar-responsive">
          <div className="ddw__navbar-responsive-close">
            <button
              onClick={onShowNavBarClickedHandler}
              className="ddw__navbar-responsive-close-button"
            >
              <AiOutlineClose />
            </button>
          </div>
          <div className="ddw__navbar-responsive-items">
            <LinkScroll
              to="aboutme"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Over mij
            </LinkScroll>
            <LinkScroll
              to="projects"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Projecten
            </LinkScroll>
            <LinkScroll
              to="contact"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Contact
            </LinkScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
