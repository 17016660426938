import React from "react";

import "./NavbarProjectPage.css";

import Logo from "../../assets/ddw-logo.png";
import { Link as LinkDom} from "react-router-dom";

const NavbarProjectPage = () => {
  return (
    <div className="ddw__interieur-navbar-container-project">
      <div className="ddw__interieur-navbar-logo">
      <LinkDom to="/">
        <img src={Logo} alt={Logo} />
        </LinkDom>
      </div>
    </div>
  );
};

export default NavbarProjectPage;
