import React, { useEffect, useState } from "react";

import useHttp from "../hooks/use-http";
import ProjectGroup from "../routes/ProjectGroup";

import "./ProjectOverview.css";
import NavbarProjectPage from "./NavbarProjectPage";

const ProjectOverview = () => {
  const [projects, setProjects] = useState([]);
  const { sendRequest, loading } = useHttp();

  const retrieveProjects = (p) => {
    // Used the id value that firebase has given me to access the list
    setProjects(p["-NchR2zMfi_NtxVw5NdZ"]);
  };

  useEffect(() => {
    const requestInfo = {
      method: "GET",
    };

    sendRequest(requestInfo, retrieveProjects);
  }, []);

  const title = "Projectenoverzicht";

  console.log(projects);

  return (
    <div className="ddw__projectsoverview-container">
      <div className="ddw__projectsoverview-header">
        <NavbarProjectPage />
      </div>
      <div className="ddw__projectsoverview-content-container">
        <div className="ddw__projectsoverview-h1">
          <h1>{title}</h1>
        </div>
        {projects.map((project) => {
          return (
            <ProjectGroup
              key={project.category_id}
              name={project.category_name}
              photos={project.images}
              loading={loading}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectOverview;
