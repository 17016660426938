import React from 'react';

import './ProjectItem.css';

const ProjectItem = (props) => {
  return (
    <div className="ddw__project-item">
        <img src={props.img} alt={props.img}/>
        <div className="ddw__project-item_overlay">
            <p>{props.name}</p>
        </div>
    </div>
  )
}

export default ProjectItem