import React, { useState } from "react";

import "./ProjectGroup.css";
import ProjectThumbnail from "./ProjectThumbnail";


const ProjectGroup = (props) => {
  const [photo, setPhoto] = useState({
    photo: props.photos[0].image,
    id: props.photos[0].id,
  });

  const changePicture = (img, id) => {
    setPhoto({
      photo: img,
      id: id,
    });
  };

  return (
    <div className="ddw__projectgroup-container">
      <div className="ddw__projectgroup-content-container">
        <h2>{props.name}</h2>
        <div className="ddw__projectgroup-main-picture">
          <img
            src={`data:image/jpg;base64,${photo.photo}`}
            alt={`data:image/jpg;base64,${photo.photo}`}
          />
        </div>
        <div className="ddw__projectgroup-thumbnails">
          {props.photos.map((thumbnail) => {
            return (
              <ProjectThumbnail
                key={thumbnail.id}
                id={thumbnail.id}
                photo={thumbnail}
                changePicture={changePicture}
                selectedId={photo.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectGroup;
