import "./App.css";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Aboutme from "./components/Aboutme";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <section id="Home">
        <Navbar />
        <Home />
      </section>
      <div className="ddw__body">
        <section id="About-me">
          <Aboutme />
        </section>
        <section id="Projects">
          <Projects />
        </section>
      </div>
      <section id="Footer">
        <Footer/>
      </section>
    </div>
  );
}

export default App;
