import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Projects.css";

import ProjectItem from "./ProjectItem";

import Dak from "../../assets/images/Dak.jpg";
import Inrichting from "../../assets/images/Inrichting.jpg";
import Keuken from "../../assets/images/Keuken.jpg";
import Meubel from "../../assets/images/Meubel.jpg";

const projectList = [
  {
    name: "Dak",
    img: Dak,
  },
  {
    name: "Inrichting",
    img: Inrichting,
  },
  {
    name: "Keuken",
    img: Keuken,
  },
  {
    name: "Meubel",
    img: Meubel,
  },
];

const Projects = (props) => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    navigate('/projecten');
  }

  return (
    <div className="ddw__projects-container" id="projects">
      <div className="ddw__projects-title">
        <h1> Projecten</h1>
        <p>
          Hieronder vind u een klein overzicht van de projecten waar ik aan heb
          gewerkt.
        </p>
      </div>
      <div className="ddw__projects-items-container">
        {projectList.map((project) => {
          return <ProjectItem name={project.name} img={project.img} />;
        })}
        <p></p>
      </div>
      <div className="ddw__projects-more-projects">
        <button onClick={navigateHandler}>Zie meer</button>
      </div>
    </div>
  );
};

export default Projects;
